<script lang="ts" setup>
import { Image as DatocmsImage } from 'vue-datocms'

import { isNonNullAndDefined } from '~/utils/type-guards'

import type { NavbarFragment } from '~/graphql/generated'

type Props = {
  logo: NavbarFragment['logo']
}

withDefaults(defineProps<Props>(), {
  logo: null,
})

const route = useRoute()
</script>

<template>
  <NuxtLink :to="route.path" class="tw-mr-4xl">
    <!-- Used when image isn't SVG -->
    <DatocmsImage
      v-if="isNonNullAndDefined(logo?.responsiveImage)"
      :data="logo.responsiveImage"
    />
    <!-- For SVG image types -->
    <img
      v-else-if="isNonNullAndDefined(logo?.url)"
      :src="logo.url"
      :alt="logo.alt || ''"
      width="auto"
      class="tw-h-2xl lg:tw-h-3xl"
    />
  </NuxtLink>
</template>
