<script lang="ts" setup>
import { UIButton } from '@groover-dev/groover-ui'
import { storeToRefs } from 'pinia'

import NavbarLinkList from './NavbarLinkList.vue'
import NavbarLogo from './NavbarLogo.vue'
import NavbarMobile from './NavbarMobile.vue'

import LanguageSwitcherV2 from '~/components/ui/LanguageSwitcherV2.vue'

import { useDatoNavbarHelpers } from '~/composables/useDatoNavbarHelpers'

import { useMiscResizeStore } from '~/stores/miscResize'

import { isNonNullAndDefined } from '~/utils/type-guards'

import { Breakpoints } from '~/enums/breakpoints'

import type {
  NavbarFragment,
  StringMultiLocaleField,
} from '~/graphql/generated'

type Props = NavbarFragment & {
  landingPageAvailableLocales?: StringMultiLocaleField[]
}
type Emits = {
  close: []
}

withDefaults(defineProps<Props>(), {
  logo: null,
  leftSideNavLinks: () => [],
  rightSideNavLinks: () => [],
  ctas: () => [],
  landingPageAvailableLocales: () => [],
  mobileMenuCtas: () => [],
  mobileNavbarCta: null,
})
const emit = defineEmits<Emits>()

const { SCREEN_WIDTH } = storeToRefs(useMiscResizeStore())

const { getCtaType, getCtaDataTestID, performCtaAction } =
  useDatoNavbarHelpers(emit)
</script>

<template>
  <header class="tw-sticky tw-top-0 tw-z-20 lg:tw-bg-surface lg:tw-shadow-base">
    <!-- v-if not used on mobile navbar because it makes SSR load the page without any navbar -->
    <NavbarMobile
      :id
      class="lg:tw-hidden"
      :logo
      :left-side-nav-links
      :right-side-nav-links
      :mobile-navbar-cta
      :mobile-menu-ctas
      :landing-page-available-locales
    />
    <!-- desktop nav -->
    <nav
      v-if="SCREEN_WIDTH >= Breakpoints.lg"
      id="siteNav"
      class="tw-hidden tw-h-[76px] tw-items-center tw-px-xl lg:tw-flex min-[1280px]:tw-mx-auto min-[1280px]:tw-max-w-[1200px] min-[1280px]:tw-px-0"
    >
      <NavbarLogo v-if="isNonNullAndDefined(logo)" :logo="logo" />
      <NavbarLinkList
        :nav-links="leftSideNavLinks"
        class="tw-flex-1 tw-gap-x-3xl"
      />
      <NavbarLinkList :nav-links="rightSideNavLinks" />
      <LanguageSwitcherV2
        class="tw-ml-3xl tw-mr-4xl"
        :landing-page-available-locales
      />
      <div v-if="ctas" class="tw-flex tw-items-center tw-gap-x-2xl">
        <div v-for="cta in ctas" :key="cta.id">
          <UIButton
            :text="cta.text"
            :variant="getCtaType(cta.ctaType)"
            :data-test-id="getCtaDataTestID(cta.action)"
            size="small"
            class="tw-px-md"
            :class="{
              'tw-bg-orange-500': cta.ctaType === 'primary',
              '!tw-text-orange-500': cta.ctaType === 'transparent',
            }"
            @click="performCtaAction(cta.action, cta.url)"
          />
        </div>
      </div>
    </nav>
  </header>
</template>
