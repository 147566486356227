<script setup lang="ts">
import { UIButton } from '@groover-dev/groover-ui'

import { useBandSignupStore } from '~/stores/bandSignup'

import { trackEvent } from '~/helpers/LegacyTrackEvent'

import type { CtaFragment } from '~/graphql/generated'

type Props = CtaFragment

type Emits = {
  close: []
}

const props = withDefaults(defineProps<Props>(), {
  text: '',
  type: 'primary',
  showSignupModal: false,
  externalUrl: '',
})
const emit = defineEmits<Emits>()

const route = useRoute()
const { SET_DISPLAY: BAND_SIGNUP_SET_DISPLAY } = useBandSignupStore()

const isLink = computed(() =>
  Boolean(props.externalUrl && !props.showSignupModal),
)

function handleSignupClick() {
  if (!props.showSignupModal) return

  BAND_SIGNUP_SET_DISPLAY(true)
  emit('close')
  trackEvent(
    {
      category: 'Landing',
      action: 'Open sign up dialog',
      label: 'Introduction / CTA',
    },
    route,
  )
}
</script>

<template>
  <UIButton
    :text="text"
    :variant="props.ctaType === 'primary' ? 'primary' : 'outlined'"
    :class="{
      'tw-bg-orange-500': props.ctaType === 'primary',
      '!tw-border-orange-500 !tw-text-orange-500': props.ctaType === 'outlined',
    }"
    size="large"
    :to="isLink ? externalUrl! : undefined"
    :target="isLink ? '_blank' : undefined"
    @click="handleSignupClick"
  />
</template>
