<script setup lang="ts">
import { useGetProfilePicture } from '~/composables/useGetProfilePicture'

import { useTagStore } from '~/stores/tag'

import { countriesArray } from '~/utils/Countries'

import type { Influencer, StatsV3Influencer } from '~/types/influencer'

type Props = {
  influencer: StatsV3Influencer | Influencer
}
const props = defineProps<Props>()

const { t } = useI18n()
const { getProfilePictureUrl } = useGetProfilePicture()

const { GET_TAGS_FROM_IDS, FETCH: FETCH_TAGS } = useTagStore()
await callOnce('FETCH_TAGS', FETCH_TAGS)

const countries = computed<string>(() => {
  return GET_TAGS_FROM_IDS(props.influencer.tags?.identity.country ?? [])
    .map((tag) => t(`tags.country.${tag.name}`))
    .join(', ')
})

const kinds = computed<string>(() => {
  return GET_TAGS_FROM_IDS(
    props.influencer.tags?.identity.influencer_kind ?? [],
  )
    .map((tag) => t(`tags.influencer_kind.${tag.name}`))
    .join(', ')
})

const entity = computed<string>(() => {
  return props.influencer.entity
})

const picture = computed<string>(() => {
  return getProfilePictureUrl({
    slug: props.influencer.has_profile_picture
      ? props.influencer.slug
      : undefined,
    size: '200_200',
    target: 'profile_picture',
    kind: 'influencer',
  })
})

const flagUrl = computed<string>(() => {
  const country = countriesArray.find(
    (c) =>
      c.name === countries.value.toLowerCase().replace(/ /g, '-') ||
      c.name_official === countries.value.toLowerCase().replace(/ /g, '-'),
  )

  if (country && country.code2l)
    return `https://flagsapi.com/${country.code2l}/flat/64.png`

  return ''
})
</script>

<template>
  <div>
    <div class="tw-flex tw-gap-2xs tw-rounded-md tw-bg-fill-alt tw-p-2xs">
      <div>
        <img
          class="tw-size-[76px] tw-rounded tw-border-2"
          :src="picture"
          alt=""
          loading="lazy"
        />
      </div>
      <div class="tw-flex tw-w-min tw-flex-col tw-gap-2xs">
        <div
          class="tw-flex tw-h-full tw-min-w-[90px] tw-items-center tw-justify-center tw-rounded-xs tw-bg-fill-alt-hover"
        >
          <p
            class="tw-text-body-xs tw-line-clamp-2 tw-p-2xs tw-text-center tw-text-white"
          >
            <b>{{ entity }}</b>
          </p>
        </div>
        <div class="tw-flex tw-gap-2xs">
          <div
            class="tw-text-body-xs tw-flex tw-items-center tw-whitespace-nowrap tw-rounded-xs tw-bg-[#737373] tw-px-xs !tw-font-medium tw-text-inverse"
          >
            {{ kinds }}
          </div>
          <div
            class="tw-flex tw-size-2xl tw-items-center tw-justify-center tw-rounded-xs tw-bg-fill-alt-hover"
          >
            <div class="tw-w-lg">
              <ClientOnly>
                <img
                  v-if="flagUrl"
                  :src="flagUrl"
                  :alt="countries"
                  :title="countries"
                  loading="lazy"
                />
              </ClientOnly>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
