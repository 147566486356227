<script setup lang="ts">
import { h } from 'vue'
import {
  Image as DatocmsImage,
  StructuredText as DatocmsStructuredText,
} from 'vue-datocms'

import DatoSectionContainer from '~/components/dato/DatoSectionContainer.vue'
import IconH3PricingComponent from '~/components/dato/TextImage/IconH3PricingComponent.vue'
import IconTextComponent from '~/components/dato/TextImage/IconTextComponent.vue'

import { isNonNullAndDefined } from '~/utils/type-guards'

import type {
  CommonContentRecord,
  IconH3PricingFragment,
  IconTextFragment,
  TextImageFragment,
} from '~/graphql/generated'

type Props = TextImageFragment & { commonContent?: CommonContentRecord }

const props = withDefaults(defineProps<Props>(), {
  hasImageLeft: false,
  commonContent: undefined,
})

const renderBlock = ({
  record,
}: {
  record: IconH3PricingFragment | IconTextFragment
}) => {
  switch (record.__typename) {
    case 'IconTextRecord':
      return h(IconTextComponent, record)
    case 'IconH3PricingRecord':
      return h(IconH3PricingComponent, {
        ...record,
        commonContent: props.commonContent,
      })
    default:
      return null
  }
}
</script>

<template>
  <div>
    <DatoSectionContainer>
      <div
        class="tw-flex tw-flex-col tw-gap-3xl md:tw-gap-[100px] lg:tw-gap-[122px]"
        :class="{
          'md:tw-flex-row': !hasImageLeft,
          'md:tw-flex-row-reverse': hasImageLeft,
        }"
      >
        <div class="tw-flex tw-flex-col tw-justify-center md:tw-w-6/12">
          <h2 class="tw-text-h3 tw-mb-3xl tw-text-center md:tw-text-left">
            {{ title }}
          </h2>
          <div class="prose-base">
            <DatocmsStructuredText
              v-if="description"
              :data="description"
              :render-block="renderBlock"
            />
          </div>
        </div>
        <div class="tw-flex md:tw-w-5/12">
          <DatocmsImage
            v-if="isNonNullAndDefined(illustration?.responsiveImage)"
            :data="illustration?.responsiveImage"
            alt="Illustration"
            class="dato-img"
          />
        </div>
      </div>
    </DatoSectionContainer>
  </div>
</template>

<style lang="scss" scoped>
:deep(.prose-base) {
  // Hide these to control spacing between elements with tags above
  br {
    display: none;
  }
}

.dato-img {
  :deep(img) {
    @apply tw-max-w-full tw-object-contain;
  }

  // Override dato's max-width for mobile only
  @media screen and (max-width: 768px) {
    max-width: 100% !important;
  }
}
</style>
