<script setup lang="ts">
import { storeToRefs } from 'pinia'
import {
  Image as DatocmsImage,
  StructuredText as DatocmsStructuredText,
} from 'vue-datocms'

import Cta from '~/components/dato/Cta.vue'
import DatoSectionContainer from '~/components/dato/DatoSectionContainer.vue'

import { useMiscResizeStore } from '~/stores/miscResize'

import { isNonNullAndDefined } from '~/utils/type-guards'

import type { HeroBoxFragment, HeroFragment } from '~/graphql/generated'

type Props = HeroFragment | HeroBoxFragment

const props = withDefaults(defineProps<Props>(), {
  illustration: undefined,
  slideshowImages: () => [],
  ctas: () => [],
  heroSubtitle: null,
  subtitle: null,
  title: null,
})

const { SCREEN_WIDTH } = storeToRefs(useMiscResizeStore())

const currentSlideshowImage = ref<HeroBoxFragment['slideshowImages'][0] | null>(
  props.slideshowImages.length ? props.slideshowImages[0] : null,
)
const SLIDESHOW_DELAY_TIME_IN_MS = 6500
const slideshowTimeout = ref<NodeJS.Timeout | null>(null)
const displayShadowEl = ref(true)
const boxShadowElHeight = ref('1px')
const datoImgEls = useTemplateRef('datoImgs')

onMounted(() => {
  setBoxShadowElHeight()

  if (!props.slideshowImages.length || props.slideshowImages.length < 2) return

  slideshowTimeout.value = setInterval(
    () => setSlideshowImage(),
    SLIDESHOW_DELAY_TIME_IN_MS,
  )
})

onBeforeUnmount(() => clearSlideshowTimeout())

watch(SCREEN_WIDTH, () => setBoxShadowElHeight())

function setSlideshowImage() {
  const currentIndex = props.slideshowImages.findIndex(
    (image) => image.id === currentSlideshowImage.value?.id,
  )
  const slideshowLength = props.slideshowImages?.length || 0
  const nextIndex = slideshowLength === currentIndex + 1 ? 0 : currentIndex + 1
  currentSlideshowImage.value = props.slideshowImages[nextIndex]
}

function clearSlideshowTimeout() {
  if (slideshowTimeout.value) clearInterval(slideshowTimeout.value)
}

function setBoxShadowElHeight() {
  // fallback to using single image when slideshow images are not present
  if (!currentSlideshowImage.value && props.illustration?.responsiveImage) {
    // need to grab the height of the image with a query selector vs. using a ref due to the way DatoCMS images are rendered
    const height =
      document.querySelector('.dato-img > img:first-of-type')?.clientHeight || 0
    boxShadowElHeight.value = height ? `${height}px` : '1px'
    return
  }

  const imgDivEl =
    datoImgEls.value?.length &&
    datoImgEls.value.find((el) => {
      return el.id === currentSlideshowImage.value?.id
    })

  if (imgDivEl) boxShadowElHeight.value = `${imgDivEl.clientHeight}px`
}
</script>

<template>
  <DatoSectionContainer>
    <div
      id="hero"
      class="tw-flex tw-flex-col tw-gap-4xl md:tw-flex-row md:tw-items-center md:tw-gap-lg lg:tw-gap-[122px]"
    >
      <div
        :class="[
          'tw-flex tw-flex-col tw-justify-center',
          {
            'md:tw-w-1/2': illustration?.responsiveImage,
            'md:tw-items-center': !illustration?.responsiveImage,
          },
        ]"
      >
        <h1
          v-if="title"
          class="tw-text-h1 tw-mb-lg"
          :class="{
            'tw-text-center md:tw-text-left': illustration?.responsiveImage,
            'tw-text-center md:tw-w-10/12': !illustration?.responsiveImage,
          }"
        >
          {{ title }}
        </h1>
        <div
          v-if="heroSubtitle || subtitle"
          :class="{
            'tw-text-center md:tw-text-left': illustration?.responsiveImage,
            'md:tw-w-8/12 md:tw-text-center': !illustration?.responsiveImage,
          }"
        >
          <DatocmsStructuredText
            :data="heroSubtitle || subtitle"
            class="tw-text-body"
          />
        </div>
        <div class="tw-mt-3xl tw-flex tw-flex-col tw-gap-lg md:tw-flex-row">
          <Cta v-for="cta in ctas" :key="cta.id" v-bind="cta" />
        </div>
      </div>
      <div
        v-if="slideshowImages?.length"
        class="tw-relative md:tw-flex md:tw-items-center lg:tw-block"
      >
        <!-- Box shadow element - required for Safari. Don't apply shadow to another element or you'll get a double-shadow -->
        <Transition name="fade">
          <div
            v-show="displayShadowEl"
            id="boxShadowEl"
            class="custom-shadow tw-absolute tw-left-0 tw-top-1/2 tw-w-full -tw-translate-y-1/2 tw-rounded-lg"
            :style="{ height: boxShadowElHeight }"
          ></div>
        </Transition>
        <div
          v-for="slideshowImage in slideshowImages"
          :id="slideshowImage.id"
          :key="slideshowImage.id"
          ref="datoImgs"
          class="tw-overflow-hidden"
        >
          <Transition
            name="fade"
            @leave="displayShadowEl = false"
            @after-enter="displayShadowEl = true"
          >
            <!-- use v-show here vs. v-if so screen readers can read all img alt content, not just the active slideshow image -->
            <DatocmsImage
              v-show="
                isNonNullAndDefined(currentSlideshowImage?.responsiveImage) &&
                currentSlideshowImage?.id === slideshowImage.id
              "
              :data="slideshowImage.responsiveImage"
              class="dato-img tw-rounded-lg"
            />
          </Transition>
        </div>
      </div>
      <!-- Old single image version -->
      <div
        v-else-if="illustration?.responsiveImage"
        class="tw-relative md:tw-flex md:tw-w-1/2 md:tw-items-center"
      >
        <!-- Box shadow element - required for Safari. Don't apply shadow to another element or you'll get a double-shadow -->
        <div
          v-show="displayShadowEl"
          id="boxShadowEl"
          class="custom-shadow tw-absolute tw-left-0 tw-top-1/2 tw-w-full -tw-translate-y-1/2 tw-rounded-lg"
          :style="{ height: boxShadowElHeight }"
        ></div>
        <DatocmsImage
          v-if="isNonNullAndDefined(illustration.responsiveImage)"
          :data="illustration.responsiveImage"
          class="dato-img tw-rounded-lg"
        />
      </div>
    </div>
  </DatoSectionContainer>
</template>

<style scoped lang="scss">
.custom-shadow {
  box-shadow:
    2px 3px 14px rgba(0, 0, 0, 0.1),
    7px 11px 26px rgba(0, 0, 0, 0.09),
    15px 25px 34px rgba(0, 0, 0, 0.05),
    27px 44px 42px rgba(0, 0, 0, 0.01),
    43px 69px 46px rgba(0, 0, 0, 0);
}

.dato-img {
  :deep(img) {
    @apply tw-max-w-full tw-object-contain;
  }

  // Override dato's max-width for mobile only
  @media screen and (max-width: 768px) {
    max-width: 100% !important;
  }
}

.fade-leave-active {
  @apply tw-left-0 tw-top-1/2 -tw-translate-y-1/2;
  position: absolute !important;
}

.fade-enter-active,
.fade-leave-active {
  @apply tw-transition-opacity tw-duration-[800ms] tw-ease-linear;
}

.fade-enter-active {
  @apply tw-delay-[800ms];
}

#boxShadowEl.fade-enter-active {
  @apply tw-delay-0 tw-duration-500;
}

.fade-enter-from,
.fade-leave-to {
  @apply tw-opacity-0;
}
</style>
