<script setup lang="ts">
import { UIButton } from '@groover-dev/groover-ui'
import { noop, useFocus } from '@vueuse/core'
import { storeToRefs } from 'pinia'

import { vRemoveBodyScroll } from '~/directives/RemoveBodyScroll'

import ChartsPlayer from '~/components/dato/Charts/ChartsPlayer.vue'
import ChartsTrackList from '~/components/dato/Charts/ChartsTrackList.vue'
import DatoSectionContainer from '~/components/dato/DatoSectionContainer.vue'

import { useMiscResizeStore } from '~/stores/miscResize'

import { Breakpoints } from '~/enums/breakpoints'

import type { ChartsFragment } from '~/graphql/generated'
import type { ChartsTrack } from '~/types/ChartsTrack'

type Props = ChartsFragment

withDefaults(defineProps<Props>(), {
  title: '',
  subtitle: '',
  cta: null,
})

const { SCREEN_WIDTH } = storeToRefs(useMiscResizeStore())

// set up for focusing the mobile player
const mobileChartsPlayer = ref<HTMLElement | null>(null)
const { focused: isMobileChartsPlayerFocused } = useFocus(mobileChartsPlayer)

const shouldModalOpen = ref(false)
const selectedTrack = ref<ChartsTrack | undefined>()
// mock data that will be replaced by the actual data from the API
const data = ref({
  '1': {
    track_id: 984775,
    track_name: 'Hard coded Audiomack track',
    band_id: 223753,
    band_name: 'Testing',
    track_link: 'https://audiomack.com/ti-blaze/song/beamer', // 'https://www.youtube.com/watch?v=aZ5SstVstGQ',
    score: 1.0,
  },
  '2': {
    track_id: 992576,
    track_name: 'Bleu Klein',
    band_id: 6226,
    band_name: 'Simon Beigelman',
    track_link: 'https://www.youtube.com/watch?v=1iXVH9SDTw4',
    score: 0.981134096934539,
  },
  '3': {
    track_id: 985894,
    track_name: 'By My Side',
    band_id: 272091,
    band_name: 'Thomas Tempest',
    track_link: 'https://www.youtube.com/watch?v=fU5h1NI8hA4',
    score: 0.97473179730527,
  },
  '4': {
    track_id: 949077,
    track_name: "\u00C7a m'arrange pas",
    band_id: 6226,
    band_name: 'Simon Beigelman',
    track_link: 'https://www.youtube.com/watch?v=DogdQIyMuiE',
    score: 0.9728610763781953,
  },
  '5': {
    track_id: 987089,
    track_name: 'Stoned Seclusion Blues',
    band_id: 603,
    band_name: 'Magon',
    track_link: 'https://www.youtube.com/watch?v=NejWDN5IAec',
    score: 0.9280711475303717,
  },
  '6': {
    track_id: 986785,
    track_name: 'Sin\u00F4nimos',
    band_id: 52818,
    band_name: 'Julia Smith',
    track_link: 'https://www.youtube.com/watch?v=NBRUghm3WsU',
    score: 0.9236830401483002,
  },
  '7': {
    track_id: 993619,
    track_name: 'La For\u00EAt',
    band_id: 301387,
    band_name: 'Mac Amish',
    track_link: 'https://www.youtube.com/watch?v=h9B3G7kfJPM',
    score: 0.9176165341175475,
  },
  '8': {
    track_id: 985920,
    track_name: 'Princess',
    band_id: 669,
    band_name: 'Juste Shani',
    track_link: 'https://www.youtube.com/watch?v=fkUJJpHntNo',
    score: 0.915950410626943,
  },
  '9': {
    track_id: 993695,
    track_name: 'recue\u0301rdame',
    band_id: 511921,
    band_name: 'Hipnos',
    track_link: 'https://www.youtube.com/watch?v=2xMP37zzY5I',
    score: 0.914954345144205,
  },
  '10': {
    track_id: 979815,
    track_name: 'Apple Pay [From my EP "Smooth Shine]',
    band_id: 460224,
    band_name: 'Santao',
    track_link: 'https://www.youtube.com/watch?v=dXtjV8MB3qw',
    score: 0.9048053243600107,
  },
  '11': {
    track_id: 967601,
    track_name: 'drink up',
    band_id: 99822,
    band_name: 'Adeline V. Lopez',
    track_link: 'https://on.soundcloud.com/qKtXkFeq9jxJB3v67',
    score: 0.9026883128488854,
  },
  '12': {
    track_id: 986632,
    track_name: 'Le feu',
    band_id: 7356,
    band_name: 'La Petite',
    track_link: 'https://www.youtube.com/watch?v=8NABcC-sTko',
    score: 0.90083212319468,
  },
  '13': {
    track_id: 971790,
    track_name: 'laguna azul',
    band_id: 508965,
    band_name: 'Diggsz',
    track_link: 'https://www.youtube.com/watch?v=KtXvmv9REdM',
    score: 0.8951465011786868,
  },
  '14': {
    track_id: 944644,
    track_name: 'First Burn',
    band_id: 82783,
    band_name: 'Rodney Hazard',
    track_link: 'https://youtu.be/kt24gS6JAtQ?feature=shared',
    score: 0.8813601606436046,
  },
  '15': {
    track_id: 971587,
    track_name: "Sous l'orage",
    band_id: 169405,
    band_name: 'Tianmi',
    track_link: 'https://www.youtube.com/watch?v=AApocV0DrOE',
    score: 0.8692540121247085,
  },
  '16': {
    track_id: 971784,
    track_name: "St. George's Park",
    band_id: 300308,
    band_name: 'Dialekt',
    track_link: 'https://www.youtube.com/watch?v=jIjVcScnpYI',
    score: 0.8685434115289337,
  },
  '17': {
    track_id: 956731,
    track_name: 'misty',
    band_id: 383867,
    band_name: 'Okay Alright',
    track_link: 'https://www.youtube.com/watch?v=ftRdQw5Rcpo',
    score: 0.8619975270620208,
  },
  '18': {
    track_id: 971766,
    track_name: 'Alright',
    band_id: 508953,
    band_name: 'Z\u00E9phyr',
    track_link: 'https://www.youtube.com/watch?v=-eFVANPXPns',
    score: 0.8487734395866319,
  },
  '19': {
    track_id: 940728,
    track_name: 'empty nights',
    band_id: 447938,
    band_name: 'L!aW',
    track_link: 'https://www.youtube.com/watch?v=-XmCu6My0OM',
    score: 0.840440992331883,
  },
  '20': {
    track_id: 936117,
    track_name: 'Idaho',
    band_id: 190759,
    band_name: 'None The Younger',
    track_link: 'https://www.youtube.com/watch?v=WdunvnXSFFo',
    score: 0.8371596579153995,
  },
  '21': {
    track_id: 986142,
    track_name: "It's in the Game",
    band_id: 398026,
    band_name: 'DempseyRollBoy',
    track_link: 'https://www.youtube.com/watch?v=35TCrt_KacA',
    score: 0.8371243272658603,
  },
  '22': {
    track_id: 940718,
    track_name: 'StereoHarp',
    band_id: 379810,
    band_name: 'Yo Cisco Kidd',
    track_link: 'https://www.youtube.com/watch?v=UZETMA0fzuY',
    score: 0.834070307830125,
  },
  '23': {
    track_id: 985902,
    track_name: 'After Midnight',
    band_id: 508945,
    band_name: 'Ace Butter',
    track_link: 'https://www.youtube.com/watch?v=6vjeiUtSB14',
    score: 0.8286932377100252,
  },
  '24': {
    track_id: 976147,
    track_name: 'My Brooklyn (Is Better Than Yours)',
    band_id: 80817,
    band_name: 'Loren Ber\u00ED',
    track_link: 'https://www.youtube.com/watch?v=oh80kDnggg8',
    score: 0.823585418766412,
  },
  '25': {
    track_id: 940708,
    track_name: 'Rainmakers',
    band_id: 478101,
    band_name: '7th Realm',
    track_link: 'https://www.youtube.com/watch?v=5jqc4lQyDMQ',
    score: 0.821349231287825,
  },
  '26': {
    track_id: 985889,
    track_name: 'Hana',
    band_id: 514203,
    band_name: 'LUXID AXID',
    track_link: 'https://www.youtube.com/watch?v=1qN6BZAPvM4',
    score: 0.8189162643819654,
  },
  '27': {
    track_id: 989460,
    track_name: 'Invader Zim',
    band_id: 398026,
    band_name: 'DempseyRollBoy',
    track_link: 'https://www.youtube.com/watch?v=bHze5d76Lhg',
    score: 0.8173538212931785,
  },
  '28': {
    track_id: 979548,
    track_name: 'John Dillinger',
    band_id: 166380,
    band_name: 'A.A. SARL',
    track_link: 'https://www.youtube.com/watch?v=v3sBObfV-rQ',
    score: 0.8112025507693489,
  },
  '29': {
    track_id: 964275,
    track_name: 'Tree Leaf',
    band_id: 460224,
    band_name: 'Santao',
    track_link: 'https://www.youtube.com/watch?v=NYcw_sZck1o',
    score: 0.7996690656292061,
  },
  '30': {
    track_id: 993865,
    track_name: 'When You',
    band_id: 517270,
    band_name: 'Ashorttimelater',
    track_link: 'https://www.youtube.com/watch?v=Svd3wUzJ_hs',
    score: 0.7790718071056172,
  },
  '31': {
    track_id: 1000855,
    track_name: 'slidingby',
    band_id: 510134,
    band_name: 'lowpass',
    track_link: 'https://www.youtube.com/watch?v=8590aO6qqls',
    score: 0.7766984651972517,
  },
  '32': {
    track_id: 926496,
    track_name: 'Sunset Highway',
    band_id: 471950,
    band_name: 'Hary.BoY',
    track_link: 'https://www.youtube.com/watch?v=VDppna6NfII',
    score: 0.7612814316518354,
  },
  '33': {
    track_id: 946650,
    track_name: 'lighthouse in the dark',
    band_id: 204262,
    band_name: 'FELIX',
    track_link:
      'https://soundcloud.com/felix96580/lighthouse-in-the-dark?si=b656e0f1debb40c681e9d11292168cf6&utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing',
    score: 0.7526680615041105,
  },
})

// format the data to be used in the component as an array
const computedList = computed<ChartsTrack[]>(() => Object.values(data.value))

const ctaSize = computed(() => {
  if (SCREEN_WIDTH.value <= Breakpoints.sm) return 'small'
  if (SCREEN_WIDTH.value < Breakpoints.lg) return 'medium'
  return 'large'
})

const isModalDisplayed = computed(() => {
  return (
    SCREEN_WIDTH.value < Breakpoints.md &&
    selectedTrack.value &&
    shouldModalOpen.value
  )
})

onMounted(() => {
  selectedTrack.value = computedList.value[0]
})

function handleTrackSelected(payload: { trackId: number }) {
  selectedTrack.value = computedList.value.find(
    (track) => track.track_id === payload.trackId,
  )
  shouldModalOpen.value = true
}

function handleMobileChartsPlayerClose() {
  shouldModalOpen.value = false
}
</script>

<template>
  <DatoSectionContainer class="tw-relative tw-flex-col">
    <div class="tw-flex tw-flex-col tw-gap-lg md:tw-gap-y-2xl lg:tw-flex-row">
      <div
        v-if="title || subtitle"
        class="tw-flex tw-w-full tw-flex-col tw-gap-y-lg md:tw-gap-y-sm"
      >
        <h2 v-if="title" class="tw-text-h3">{{ title }}</h2>
        <p v-if="subtitle" class="tw-text-body-sm md:tw-text-body">
          {{ subtitle }}
        </p>
      </div>
      <UIButton
        v-if="cta?.externalUrl"
        :to="cta.externalUrl"
        :text="cta.text"
        target="_blank"
        class="tw-bg-orange-500 md:tw-place-self-start lg:tw-place-self-end"
        :size="ctaSize"
      />
    </div>
    <hr
      v-if="title || subtitle || cta"
      class="tw-border-discrete-3 tw-m-auto tw-mt-2xl tw-hidden tw-w-full md:tw-block"
    />
    <div
      v-if="computedList.length"
      class="md:tw-grid md:tw-grid-cols-[1fr_40.5%] md:tw-items-start md:tw-gap-x-lg"
      :class="{
        'tw-mt-2xl md:tw-mt-4xl lg:tw-mt-6xl': title || subtitle || cta,
      }"
    >
      <ChartsTrackList
        :track-list="computedList"
        @track-selected="handleTrackSelected"
      />
      <!-- Mobile player -->
      <Transition name="fade" @after-enter="isMobileChartsPlayerFocused = true">
        <Teleport v-if="isModalDisplayed" to="#modal-backdrop-overlay">
          <div
            v-remove-body-scroll="isModalDisplayed"
            class="tw-fixed tw-left-0 tw-top-0 tw-z-20 tw-flex tw-min-h-dvh tw-min-w-full tw-items-end tw-bg-[#000000] tw-bg-opacity-50 tw-p-lg md:tw-hidden"
            role="dialog"
            aria-labelledby="trackName"
            @click.stop="shouldModalOpen = false"
          >
            <ChartsPlayer
              v-if="selectedTrack"
              ref="mobileChartsPlayer"
              :track-url="selectedTrack.track_link"
              :track-artist="selectedTrack.band_name"
              :track-name="selectedTrack.track_name"
              class="tw-w-full"
              tabindex="0"
              @click.stop="noop"
              @close="handleMobileChartsPlayerClose"
            />
          </div>
        </Teleport>
      </Transition>
      <!-- Desktop player -->
      <Transition name="fade">
        <ChartsPlayer
          v-if="SCREEN_WIDTH >= Breakpoints.md && selectedTrack"
          :track-url="selectedTrack.track_link"
          :track-artist="selectedTrack.band_name"
          :track-name="selectedTrack.track_name"
          class="tw-sticky tw-right-0 tw-top-[140px]"
        />
      </Transition>
    </div>
  </DatoSectionContainer>
</template>
