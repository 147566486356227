<script setup lang="ts">
import { UIIcon } from '@groover-dev/groover-ui'
import { storeToRefs } from 'pinia'
import {
  Image as DatocmsImage,
  StructuredText as DatocmsStructuredText,
} from 'vue-datocms'

import DatoSectionContainer from '~/components/dato/DatoSectionContainer.vue'

import { useMiscResizeStore } from '~/stores/miscResize'

import { isNonNullAndDefined } from '~/utils/type-guards'

import { Breakpoints } from '~/enums/breakpoints'

import type { TestimonialFragment } from '~/graphql/generated'

type Props = TestimonialFragment
type TestimonialItem = TestimonialFragment['items'][number]

const props = withDefaults(defineProps<Props>(), {
  title: '',
  subtitle: '',
  items: () => [],
})

const { SCREEN_WIDTH } = storeToRefs(useMiscResizeStore())

const CAROUSEL_DELAY_TIME_IN_MS = 7000
const MIN_CAROUSEL_HEIGHT = 270
const displayedItem = ref<TestimonialItem | null>(
  props.items.length ? props.items[0] : null,
)
const carouselTimeout = ref<NodeJS.Timeout | null>(null)
const carouselContainer = ref<HTMLDivElement | null>(null)
const carouselContainerHeight = ref<number>(MIN_CAROUSEL_HEIGHT)
const quoteContainer = ref<HTMLQuoteElement | null>(null)

onMounted(() => {
  if (!props.items.length) return

  carouselTimeout.value = setInterval(() => {
    showItem(1)
  }, CAROUSEL_DELAY_TIME_IN_MS)

  checkAndSetCarouselContainerHeight()
  window.addEventListener('resize', handleResize)
})

onBeforeUnmount(() => {
  clearCarouselTimeout()
  window.removeEventListener('resize', handleResize)
})

function clearCarouselTimeout() {
  if (carouselTimeout.value) clearInterval(carouselTimeout.value)
}

function handlePrevClick() {
  clearCarouselTimeout()
  showItem(-1)
}

function handleNextClick() {
  clearCarouselTimeout()
  showItem(1)
}

function showItem(direction: -1 | 1) {
  if (!props.items.length || displayedItem.value === null) return

  displayedItem.value =
    props.items[
      (props.items.indexOf(displayedItem.value) +
        direction +
        props.items.length) %
        props.items.length
    ]
}

function checkAndSetCarouselContainerHeight() {
  if (!carouselContainer.value || !quoteContainer.value) return

  const PADDING_SIZE = 8
  const newQuoteContainerHeight =
    SCREEN_WIDTH.value >= Breakpoints.md
      ? quoteContainer.value.clientHeight + PADDING_SIZE
      : carouselContainer.value.clientHeight

  if (newQuoteContainerHeight > carouselContainerHeight.value) {
    carouselContainer.value.style.minHeight = `${newQuoteContainerHeight}px`
    carouselContainerHeight.value = newQuoteContainerHeight
  }
}

function resetCarouselHeight() {
  carouselContainer.value?.style.removeProperty('min-height')
  carouselContainerHeight.value = MIN_CAROUSEL_HEIGHT
}

function handleResize() {
  resetCarouselHeight()
  checkAndSetCarouselContainerHeight()
}
</script>

<template>
  <DatoSectionContainer>
    <div
      class="tw-grid tw-w-full tw-gap-y-xl tw-overflow-hidden tw-text-inverse md:tw-grid-cols-[1fr_2fr] md:tw-items-center md:tw-gap-x-xl"
    >
      <!-- left panel -->
      <div class="tw-text-center md:tw-text-left">
        <h2 v-if="title" class="tw-text-h3">
          {{ title }}
        </h2>
        <p v-if="subtitle" class="tw-text-body tw-mt-lg">{{ subtitle }}</p>
        <div
          v-if="props.items.length > 1"
          class="tw-mt-3xl tw-flex tw-items-center tw-justify-center tw-gap-x-lg md:tw-justify-start"
        >
          <!--TODO: replace with UIButton -->
          <button
            type="button"
            class="tw-flex tw-size-[36px] tw-items-center tw-justify-center tw-rounded-sm tw-border tw-border-orange-500 tw-bg-transparent tw-text-center tw-text-orange-500 md:tw-size-[44px]"
            @click="handlePrevClick()"
          >
            <UIIcon name="mdi:chevron-left" />
          </button>
          <!--TODO: replace with UIButton -->
          <button
            type="button"
            class="tw-flex tw-size-[36px] tw-items-center tw-justify-center tw-rounded-sm tw-border tw-border-orange-500 tw-bg-transparent tw-text-center tw-text-orange-500 md:tw-size-[44px]"
            @click="handleNextClick()"
          >
            <UIIcon name="mdi:chevron-right" />
          </button>
        </div>
      </div>
      <!-- right panel -->
      <div ref="carouselContainer" class="tw-relative tw-grid tw-items-center">
        <transition
          name="fade-and-scale"
          @after-enter="checkAndSetCarouselContainerHeight()"
        >
          <div
            v-if="displayedItem"
            :key="displayedItem.id"
            class="fade-and-scale-out tw-flex tw-w-full tw-flex-col tw-items-center tw-rounded-sm tw-bg-fill-alt-hover tw-p-2xs md:tw-flex-row md:tw-gap-x-sm"
          >
            <div
              class="tw-relative tw-grid tw-max-h-[320px] tw-w-full tw-items-center tw-overflow-hidden tw-rounded-xs md:tw-mx-[unset] md:tw-max-h-[none] md:tw-max-w-[184px] lg:tw-max-w-[270px]"
            >
              <DatocmsImage
                v-if="
                  isNonNullAndDefined(displayedItem?.image?.responsiveImage)
                "
                :data="displayedItem?.image?.responsiveImage"
                picture-class="tw-aspect-square tw-object-cover md:tw-aspect-auto"
              />
            </div>
            <div
              ref="quoteContainer"
              class="tw-flex tw-w-full tw-flex-1 tw-shrink-0 tw-flex-col tw-px-md md:tw-block md:tw-px-lg md:tw-pb-md"
            >
              <blockquote
                class="quotes tw-text-body tw-mt-lg tw-flex-1 lg:tw-mt-md"
              >
                <DatocmsStructuredText
                  :data="displayedItem.multiLineDescription"
                />
              </blockquote>
              <div
                class="tw-mb-2xs tw-mt-2xl tw-flex tw-items-center tw-justify-between md:tw-mb-zero"
              >
                <span class="tw-text-body-lg tw-font-bold tw-text-link-inverse">
                  {{ displayedItem.name }}
                </span>
                <NuxtLink
                  v-if="displayedItem.link && displayedItem.linkText"
                  :to="displayedItem.link"
                  class="tw-text-body tw-flex tw-items-center tw-gap-x-2xs tw-text-nowrap tw-text-inverse"
                  target="_blank"
                  >{{ displayedItem.linkText }}
                  <UIIcon name="mdi:chevron-right"
                /></NuxtLink>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </DatoSectionContainer>
</template>

<style scoped lang="scss">
@keyframes fade-and-scale-out {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0.9);
  }
}

@keyframes fade-and-scale-in {
  0% {
    opacity: 0;
    transform: scale(1.1);
  }
  33% {
    opacity: 0;
    transform: scale(1.1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@media (prefers-reduced-motion: no-preference) {
  .fade-and-scale-enter-active {
    animation: fade-and-scale-in 750ms cubic-bezier(0.61, 0.09, 0, 1.03);
  }

  .fade-and-scale-leave-active {
    @apply tw-absolute;
    animation: fade-and-scale-out 500ms cubic-bezier(0.61, 0.09, 0, 1.03);
  }
}

.quotes {
  &:deep(p:first-of-type)::before {
    content: open-quote;
  }

  &:deep(p:last-of-type)::after {
    content: close-quote;
  }
}
</style>
