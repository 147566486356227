<script setup lang="ts">
import DatoSectionContainer from '~/components/dato/DatoSectionContainer.vue'
import SeoFooter from '~/components/seo/footer/index.vue'

import { useGetSeoGenericCuratorList } from '~/composables/useGetSeoGenericCuratorList'

import { useTagStore } from '~/stores/tag'

import type { SeoFooterFragment } from '~/graphql/generated'

type Props = SeoFooterFragment

defineProps<Props>()

const { FETCH: FETCH_TAGS } = useTagStore()
const { data: seoLists } = await useGetSeoGenericCuratorList()
await callOnce('FETCH_TAGS', FETCH_TAGS)
</script>

<template>
  <DatoSectionContainer>
    <SeoFooter :lists="seoLists" class="!tw-p-zero" />
  </DatoSectionContainer>
</template>
