export function useDraggableSlider() {
  const isMouseDown = ref(false)
  const mouseDownX = ref(0)
  const mouseUpX = ref(0)
  const containerStartTransformValue = ref<number | null>(null)

  function handleDraggableMouseDown(
    e: MouseEvent | TouchEvent,
    containerEl: HTMLElement | HTMLDivElement | null,
  ) {
    e.preventDefault()
    isMouseDown.value = true
    mouseDownX.value = getStartingX(e)

    if (!containerStartTransformValue.value && containerEl) {
      containerStartTransformValue.value = parseFloat(
        // finds percentage value from transform translate3d's x property
        containerEl.style.transform.match(/-?\d+.\d+%/g)?.[0] ?? '0',
      )
    }
  }

  function handleDraggableMouseUp(
    e: MouseEvent | TouchEvent,
    nextCallbackFn: () => void,
    prevCallbackFn: () => void,
  ) {
    if (!isMouseDown.value) return

    isMouseDown.value = false
    mouseUpX.value =
      e instanceof MouseEvent ? e.clientX : (e.changedTouches[0]?.clientX ?? 0)

    containerStartTransformValue.value = null

    // dragged from right to left
    if (
      mouseDownX.value > mouseUpX.value &&
      typeof nextCallbackFn === 'function'
    )
      nextCallbackFn()
    // dragged from left to right
    if (
      mouseDownX.value < mouseUpX.value &&
      typeof prevCallbackFn === 'function'
    )
      prevCallbackFn()
  }

  function handleDraggableMouseMove(
    e: MouseEvent | TouchEvent,
    containerEl: HTMLElement | HTMLDivElement | null,
  ) {
    requestAnimationFrame(() => {
      if (
        !isMouseDown.value ||
        !containerEl ||
        containerStartTransformValue.value === null
      )
        return

      const mouseMoveX = getStartingX(e)
      const diff = mouseMoveX - mouseDownX.value
      const diffPercent = (diff / containerEl.clientWidth) * 100
      const combined = containerStartTransformValue.value + diffPercent

      containerEl.style.transform = `translate3d(${combined}%, 0px, 0px)`
    })
  }

  function getStartingX(e: TouchEvent | MouseEvent) {
    return e instanceof MouseEvent
      ? e.clientX
      : (e.targetTouches[0]?.clientX ?? 0)
  }

  return {
    isMouseDown,
    mouseDownX,
    mouseUpX,
    containerStartTransformValue,
    handleDraggableMouseDown,
    handleDraggableMouseUp,
    handleDraggableMouseMove,
  }
}
