<script setup lang="ts">
import { useIntersectionObserver } from '@vueuse/core'

import DatoSectionContainer from '~/components/dato/DatoSectionContainer.vue'

import type { TitleSectionFragment } from '~/graphql/generated'

type Props = TitleSectionFragment

const props = withDefaults(defineProps<Props>(), {
  title: '',
  subtitle: '',
  startRevealed: true,
})

const revealed = ref(props.startRevealed)
const titleReferrence = ref<null | HTMLElement>(null)

const { stop: removeIntersectionObserver } = useIntersectionObserver(
  titleReferrence,
  ([{ isIntersecting }]) => {
    if (!isIntersecting) return

    removeIntersectionObserver()
    revealed.value = true
  },
  {
    rootMargin: '-128px',
  },
)
</script>

<template>
  <DatoSectionContainer>
    <div
      ref="titleReferrence"
      class="tw-text-center tw-transition-all tw-duration-500 lg:tw-w-10/12"
      :class="{
        'tw-translate-y-xl tw-opacity-0': !revealed,
      }"
    >
      <h2 v-if="title" class="tw-text-h3 tw-w-full">{{ title }}</h2>
      <p v-if="subtitle" class="tw-text-body tw-mt-lg lg:tw-mt-2xl">
        {{ subtitle }}
      </p>
    </div>
  </DatoSectionContainer>
</template>
