<script setup lang="ts">
import { UIIcon } from '@groover-dev/groover-ui'

import TrackUniversalPlayer from '~/components/track/universalPlayer.vue'

import { useGetMediaUrl } from '~/composables/useGetMediaUrl'

type Props = {
  trackArtist: string
  trackName: string
  trackUrl: string
}

type Emits = {
  close: []
}

defineProps<Props>()
const emit = defineEmits<Emits>()

const { getMediaUrl } = useGetMediaUrl()
</script>

<template>
  <article
    class="tw-flex tw-flex-col tw-items-center tw-gap-y-sm tw-rounded-lg tw-bg-fill-alt tw-px-sm tw-pb-lg tw-pt-2xs"
  >
    <button
      type="button"
      class="tw-flex tw-w-full tw-justify-center md:tw-hidden"
      @click="emit('close')"
    >
      <span class="tw-sr-only">{{ $t('common.close') }}</span>
      <UIIcon
        name="mdi:chevron-down"
        size="sm"
        class="tw-scale-110 tw-text-icon-discrete3"
        aria-hidden
      />
    </button>
    <TrackUniversalPlayer
      class="tw-overflow-hidden tw-rounded-sm"
      :link="trackUrl"
    />
    <section class="tw-flex tw-flex-col tw-items-center">
      <img
        :src="getMediaUrl('GrooverChartLogoWhite.svg')"
        class="tw-h-xl"
        height="20px"
        alt="Groover Charts Logo"
        loading="lazy"
      />
      <h3
        id="trackName"
        class="tw-text-h5 tw-mt-2xl tw-text-center tw-text-inverse md:tw-text-h6 lg:tw-text-h5 md:tw-mt-lg"
      >
        {{ trackName }}
      </h3>
      <p
        class="tw-text-h5 tw-text-center tw-text-discrete3 md:tw-text-h6 lg:tw-text-h5"
      >
        {{ trackArtist }}
      </p>
    </section>
  </article>
</template>
