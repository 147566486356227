import { useGetDatoUrlForLink } from './useGetDatoUrlForLink'

import { useBandSignupStore } from '~/stores/bandSignup'
import { useLoginStore } from '~/stores/login'

import { trackEvent } from '~/helpers/LegacyTrackEvent'

export function useDatoNavbarHelpers(emit: (evt: 'close') => void) {
  const route = useRoute()
  const { getUrl, isExternalLink } = useGetDatoUrlForLink()

  const { SET_DISPLAY: BAND_SIGNUP_SET_DISPLAY } = useBandSignupStore()
  const { SET_DISPLAY: LOGIN_SET_DISPLAY } = useLoginStore()

  function handleSignupClick() {
    BAND_SIGNUP_SET_DISPLAY(true)
    emit('close')
    trackEvent(
      {
        category: 'Landing',
        action: 'Open sign up dialog',
        label: 'Introduction / CTA',
      },
      route,
    )
  }

  function getCtaType(ctaType: string | null = '') {
    return ctaType === 'primary'
      ? 'primary'
      : ctaType === 'transparent'
        ? 'transparent'
        : 'outlined'
  }

  async function performCtaAction(
    ctaAction: string | null = '',
    url: string | null = '',
  ) {
    return ctaAction === 'signup'
      ? handleSignupClick()
      : ctaAction === 'login'
        ? LOGIN_SET_DISPLAY(true)
        : await navigateTo(getUrl(url || ''), {
            external: isExternalLink(url || ''),
          })
  }

  function getCtaDataTestID(action: string | null = '') {
    if (action === 'signup') return 'signupInitCTA'
    if (action === 'login') return 'loginLink'
    return undefined
  }

  return {
    handleSignupClick,
    getCtaType,
    performCtaAction,
    getCtaDataTestID,
  }
}
