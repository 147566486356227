<script setup lang="ts">
import IndexInfluencer from './Influencers.vue'

import { useGetWelcomeSpotlight } from '~/composables/useGetWelcomeSpotlight'

import { provideGetInfluencerCount } from '~/api-core/Welcome/InfluencerCount'

import type { CuratorCarouselFragment } from '~/graphql/generated'
import type { Locale } from '~/types/locale'

type Props = CuratorCarouselFragment

defineProps<Props>()

const { getLandingPageInfluencers } = useGetWelcomeSpotlight()

const { locale, fallbackLocale } = useI18n()

const [{ data: landingPageInfluencers }, { data: influencerCount }] =
  await Promise.all([
    useAsyncData(
      'LandingPageInfluencers',
      () => {
        return getLandingPageInfluencers(
          locale.value as Locale,
          fallbackLocale.value.toString() as Locale,
        )
      },
      {
        default: () => ({}),
      },
    ),
    useAsyncData<number>(
      'InfluencerCount',
      () => {
        const getInfluencerCount = provideGetInfluencerCount($coreFetch)

        return getInfluencerCount()
      },
      {
        default: () => 800,
      },
    ),
  ])
</script>

<template>
  <IndexInfluencer
    :genre-to-influencer-id-map="landingPageInfluencers"
    :influencer-count="influencerCount"
    :emphasis-type="emphasizedCuratorType"
  />
</template>
