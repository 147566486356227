<script lang="ts" setup>
import { UIIcon } from '@groover-dev/groover-ui'

import ThumbnailHolder from '~/components/track/thumbnailHolder.vue'

type Props = {
  artistName: string
  trackId: number
  trackIndex: number
  trackName: string
  trackUrl: string
}

defineProps<Props>()

const isHovered = ref(false)
</script>

<template>
  <div
    :id="`track-${trackId}`"
    class="tw-flex tw-h-[78px] tw-w-full tw-cursor-pointer tw-items-center tw-border-b tw-border-discrete3 tw-py-lg tw-transition-colors focus-visible:tw-outline md:tw-h-7xl md:tw-py-sm md:tw-pr-lg lg:tw-h-[112px] lg:tw-rounded-2xl lg:tw-border-b-0 lg:tw-py-lg lg:tw-pr-2xl hover:lg:tw-bg-surface-hover"
    role="button"
    :aria-labelledby="`trackName-${trackId}`"
    tabindex="0"
    @mouseover="isHovered = true"
    @mouseleave="isHovered = false"
  >
    <!-- TODO: revisit this as it may not work well as numbers get larger -->
    <span
      class="tw-text-h5 tw-mr-2xl tw-w-2xl tw-text-right md:tw-mr-3xl md:tw-w-3xl lg:tw-mr-4xl lg:tw-w-5xl"
      >{{ trackIndex + 1 }}</span
    >
    <ThumbnailHolder
      :track-url
      class="!tw-size-4xl tw-overflow-hidden md:!tw-size-6xl lg:!tw-size-7xl"
    />
    <div
      class="tw-ml-lg tw-flex tw-flex-1 tw-flex-col tw-gap-y-3xs tw-overflow-hidden lg:tw-ml-2xl"
    >
      <span
        :id="`trackName-${trackId}`"
        class="tw-ellipsis tw-text-h5 md:tw-text-h6 lg:tw-text-h5"
        >{{ trackName }}</span
      >
      <span
        class="tw-ellipsis tw-text-body-sm tw-text-discrete lg:tw-text-body"
        >{{ artistName }}</span
      >
    </div>
    <Transition name="fade">
      <UIIcon
        v-if="isHovered"
        name="mdi:play-circle"
        size="lg"
        class="tw-ml-sm tw-hidden tw-text-icon-primary md:tw-block"
      />
    </Transition>
  </div>
</template>
