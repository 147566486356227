<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { Image as DatocmsImage } from 'vue-datocms'

import DatoSectionContainer from '~/components/dato/DatoSectionContainer.vue'

import { useMiscResizeStore } from '~/stores/miscResize'

import { isNonNullAndDefined } from '~/utils/type-guards'

import { Breakpoints } from '~/enums/breakpoints'

import type { FullWidthImageFragment } from '~/graphql/generated'

type Props = FullWidthImageFragment

defineProps<Props>()

const { SCREEN_WIDTH } = storeToRefs(useMiscResizeStore())
</script>
<template>
  <DatoSectionContainer>
    <div
      class="tw-relative tw-w-full tw-overflow-hidden tw-rounded-lg tw-object-cover tw-object-center"
    >
      <DatocmsImage
        v-if="
          SCREEN_WIDTH < Breakpoints.md &&
          isNonNullAndDefined(mobileImage?.responsiveImage)
        "
        :data="mobileImage.responsiveImage"
      />
      <DatocmsImage
        v-else-if="isNonNullAndDefined(desktopImage?.responsiveImage)"
        :data="desktopImage.responsiveImage"
      />
    </div>
  </DatoSectionContainer>
</template>
