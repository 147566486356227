<script lang="ts" setup>
import {
  UIDropdownMenuList,
  UIDropdownMenuListItem,
  UIIcon,
} from '@groover-dev/groover-ui'

import HeightExpand from '~/components/transitions/heightExpand.vue'

import { useGetDatoUrlForLink } from '~/composables/useGetDatoUrlForLink'

import type { NavbarFragment } from '~/graphql/generated'

type Props = {
  navLink:
    | NavbarFragment['leftSideNavLinks'][0]
    | NavbarFragment['rightSideNavLinks'][0]
}

defineProps<Props>()

const { getUrl } = useGetDatoUrlForLink()

const isOpen = ref(false)
const subMenuId = useId()

function toggleOpen() {
  isOpen.value = !isOpen.value
}
</script>

<template>
  <li class="padding tw-border-b tw-border-discrete3 tw-pb-2xs">
    <button
      class="tw-flex tw-w-full tw-items-center tw-justify-between tw-py-sm"
      type="button"
      :aria-expanded="isOpen"
      :aria-controls="subMenuId"
      @click="toggleOpen"
    >
      <span class="tw-text-body tw-text-default-color">{{ navLink.text }}</span>
      <UIIcon
        name="material-symbols:expand-more"
        class="tw-transform tw-text-icon-discrete2 tw-transition-transform"
        :class="{
          'tw-rotate-180': isOpen,
        }"
        size="lg"
      />
    </button>
    <HeightExpand>
      <li v-if="isOpen" :id="subMenuId">
        <UIDropdownMenuList>
          <UIDropdownMenuListItem
            v-for="subLink in navLink.navbarDropdownMenuLinks"
            :key="subLink.id"
            :to="getUrl(subLink.url || '')"
            :text="subLink.text || ''"
            :sub-text="subLink.subtext || ''"
            :left-icon-name="subLink.iconName ? subLink.iconName : undefined"
          />
        </UIDropdownMenuList>
      </li>
    </HeightExpand>
  </li>
</template>

<style scoped lang="scss">
.padding:not(:first-of-type) {
  @apply tw-pt-2xs;
}
</style>
