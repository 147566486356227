<script setup lang="ts">
import DatoSectionContainer from '~/components/dato/DatoSectionContainer.vue'

import type { IframeFragment } from '~/graphql/generated'

type Props = IframeFragment

const props = withDefaults(defineProps<Props>(), {
  iframeAttributes: '',
})

const attributes = props.iframeAttributes ?? ''
let remainingAttributes = attributes
const parsedAttributes = [
  ...attributes.matchAll(/([a-zA-Z-]+)="([a-zA-Z0-9.,;:/?=%&\-_ ]*)"/g),
]
  // Get all `key="value"` attributes
  .map<[string, string | true]>((match) => {
    remainingAttributes = ''.concat(...remainingAttributes.split(match[0]))
    return [match[1], match[2]]
  })
// Add all `key` attributes
parsedAttributes.push(
  ...[...(remainingAttributes.match(/[a-zA-Z-]+/g) ?? [])].map(
    (match) => [match, true] as [string, true],
  ),
)
const formattedAttributes = Object.fromEntries(parsedAttributes)
</script>

<template>
  <DatoSectionContainer>
    <iframe v-bind="formattedAttributes"></iframe>
  </DatoSectionContainer>
</template>
