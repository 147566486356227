<script lang="ts" setup>
import {
  UIButtonLink,
  UIDropdownMenu,
  UIDropdownMenuList,
  UIDropdownMenuListItem,
} from '@groover-dev/groover-ui'
import { markRaw } from 'vue'

import { useGetDatoUrlForLink } from '~/composables/useGetDatoUrlForLink'

import type { NavbarFragment } from '~/graphql/generated'

type Props = {
  navLinks:
    | NavbarFragment['leftSideNavLinks']
    | NavbarFragment['rightSideNavLinks']
}

withDefaults(defineProps<Props>(), {
  navLinks: () => [],
})

const { getUrl } = useGetDatoUrlForLink()
</script>

<template>
  <ul class="tw-flex tw-items-center">
    <li v-for="link in navLinks" :key="link.id">
      <UIDropdownMenu
        :reference-component="markRaw(UIButtonLink)"
        :reference-component-attrs="{
          role: 'button',
          to: '',
          size: 'small',
          color: 'neutral',
        }"
        display-menu-on-hover
        :hide-delay="250"
      >
        <template #referenceText
          ><span>{{ link.text }}</span></template
        >
        <template #menuLists>
          <UIDropdownMenuList>
            <UIDropdownMenuListItem
              v-for="subLink in link.navbarDropdownMenuLinks"
              :key="subLink.id"
              :to="getUrl(subLink.url)"
              :text="subLink.text || ''"
              :sub-text="subLink.subtext || ''"
              :left-icon-name="subLink.iconName ? subLink.iconName : undefined"
              :data-test-id="
                getUrl(subLink.url)?.includes('/influencer/signup/')
                  ? 'curatorSignupLink'
                  : undefined
              "
            />
          </UIDropdownMenuList>
        </template>
      </UIDropdownMenu>
    </li>
  </ul>
</template>
