<script setup lang="ts">
import { UIButton } from '@groover-dev/groover-ui'
import { storeToRefs } from 'pinia'
import { NakedImage as DatocmsNakedImage } from 'vue-datocms'

import DatoSectionContainer from './DatoSectionContainer.vue'

import { useDraggableSlider } from '~/composables/useDraggableSlider'

import { useMiscResizeStore } from '~/stores/miscResize'

import { isNonNullAndDefined } from '~/utils/type-guards'

import type { ImageSlideshowFragment } from '~/graphql/generated'

type Props = ImageSlideshowFragment

const props = withDefaults(defineProps<Props>(), {
  images: () => [],
})

const { SCREEN_WIDTH } = storeToRefs(useMiscResizeStore())
const selectedImg = ref(props.images.length ? props.images[0] : null)
const imgContainerEls = useTemplateRef('imgContainer')
const sliderEl = useTemplateRef('slider')
const firstImgTransform = ref('')
const lastImgTransform = ref('')

const {
  isMouseDown,
  handleDraggableMouseDown,
  handleDraggableMouseMove,
  handleDraggableMouseUp,
} = useDraggableSlider()

const selectedImgIndex = computed(() => {
  if (selectedImg.value === null) return -1
  return props.images.findIndex((img) => img.id === selectedImg.value?.id)
})

onMounted(() => {
  cloneImgs()
  centerSelectedImg()
})

watch(SCREEN_WIDTH, () => {
  firstImgTransform.value = ''
  lastImgTransform.value = ''
  selectedImg.value = props.images.length ? props.images[0] : null
  centerSelectedImg()
})

function handleTransitionEnd() {
  if (!sliderEl.value) return
  sliderEl.value.style.transition = 'none'

  // reset the transform of the slider to the original position of the first or last img
  if (selectedImg.value === props.images[0])
    sliderEl.value.style.transform = firstImgTransform.value
  if (selectedImg.value === props.images[props.images.length - 1])
    sliderEl.value.style.transform = lastImgTransform.value
}

/**
 * Clone the images to create an infinite loop effect.
 * We create two sets of clones, one for the end of the images and one for the beginning.
 */
function cloneImgs() {
  if (!imgContainerEls.value?.length) return

  // Helper function to clone and append/prepend elements
  const cloneAndInsert = (
    el: HTMLDivElement,
    index: number,
    shouldPrepend: boolean,
  ) => {
    const clone = el.cloneNode(true) as HTMLDivElement
    clone.setAttribute(
      'id',
      `${el.id}-${shouldPrepend ? 'prepend-' : ''}clone-${index}`,
    )
    clone.setAttribute('aria-hidden', 'true')
    clone.classList.add('clone')
    if (shouldPrepend) el.parentNode?.prepend(clone)
    else el.parentNode?.append(clone)
  }

  // Create two sets of clones for infinite loop effect
  imgContainerEls.value.forEach((el, index) => cloneAndInsert(el, index, false))
  ;[...imgContainerEls.value]
    .reverse()
    .forEach((el, index) => cloneAndInsert(el, index, true))
}

/**
 * Center the selected image in the slider.
 * If a specific image is passed, center that image instead.
 *
 * @param specificImg - The specific image to center in the slider.
 */
function centerSelectedImg(specificImg?: HTMLElement | null) {
  if (!selectedImg.value && !specificImg) return
  if (!sliderEl.value) return

  sliderEl.value.style.transition = 'transform 350ms ease-in-out'

  const selectedImgEl =
    specificImg ??
    imgContainerEls.value?.find((el) => el.id === selectedImg.value?.id)

  if (selectedImgEl) {
    sliderEl.value.style.transform = `translate3d(${calculateScrollLeftPercent(selectedImgEl) * -1}%, 0px, 0px)`

    if (!firstImgTransform.value && selectedImg.value === props.images[0])
      firstImgTransform.value = sliderEl.value.style.transform
  }

  if (lastImgTransform.value) return
  const lastImg = imgContainerEls.value?.[imgContainerEls.value.length - 1]
  if (lastImg)
    lastImgTransform.value = `translate3d(${calculateScrollLeftPercent(lastImg) * -1}%, 0px, 0px)`
}

/**
 * Calculate the scroll left percentage for the image element.
 *
 * @param imgEl - The image element to calculate the scroll left percentage for.
 */
function calculateScrollLeftPercent(imgEl: HTMLElement) {
  const halfImgWidth = imgEl.clientWidth / 2
  const halfContainerWidth = sliderEl.value!.clientWidth / 2
  const scrollLeftPx = imgEl.offsetLeft + halfImgWidth - halfContainerWidth
  return (scrollLeftPx / sliderEl.value!.clientWidth) * 100
}

function setNextSelectedImg() {
  if (selectedImgIndex.value === -1) return

  const nextIndex = selectedImgIndex.value + 1
  // if we reach the end of the images, start back at the beginning, but scroll to the first cloned image to create a seamless loop.
  if (!props.images[nextIndex]) {
    selectedImg.value = props.images[0]
    centerSelectedImg(
      document.getElementById(`${selectedImg.value.id}-clone-0`),
    )
    return
  }

  selectedImg.value = props.images[nextIndex]
  centerSelectedImg()
}

function setPrevSelectedImg() {
  if (selectedImgIndex.value === -1) return

  const prevIndex = selectedImgIndex.value - 1
  if (!props.images[prevIndex]) {
    selectedImg.value = props.images[props.images.length - 1]
    centerSelectedImg(
      document.getElementById(`${selectedImg.value.id}-prepend-clone-0`),
    )
    return
  }

  selectedImg.value = props.images[prevIndex]
  centerSelectedImg()
}
</script>

<template>
  <DatoSectionContainer v-if="images.length" class="tw-mx-zero">
    <div class="tw-relative tw-w-full">
      <div
        class="tw-border-default-color tw-py-lg md:tw-border-l md:tw-border-r"
      >
        <div class="viewport tw-relative tw-overflow-hidden">
          <div
            ref="slider"
            class="tw-flex tw-h-full tw-w-full tw-cursor-grab tw-gap-x-sm"
            :class="{ 'tw-cursor-grabbing': isMouseDown }"
            @transitionend="handleTransitionEnd"
            @mousedown="handleDraggableMouseDown($event, sliderEl)"
            @touchstart="handleDraggableMouseDown($event, sliderEl)"
            @mouseup="
              handleDraggableMouseUp(
                $event,
                setNextSelectedImg,
                setPrevSelectedImg,
              )
            "
            @touchend="
              handleDraggableMouseUp(
                $event,
                setNextSelectedImg,
                setPrevSelectedImg,
              )
            "
            @mouseleave="
              handleDraggableMouseUp(
                $event,
                setNextSelectedImg,
                setPrevSelectedImg,
              )
            "
            @mousemove="handleDraggableMouseMove($event, sliderEl)"
            @touchmove="handleDraggableMouseMove($event, sliderEl)"
          >
            <div
              v-for="image in images"
              :id="image.id"
              :key="image.id"
              ref="imgContainer"
            >
              <DatocmsNakedImage
                v-if="isNonNullAndDefined(image.responsiveImage)"
                :data="image.responsiveImage"
                img-class="!tw-h-[240px] tw-rounded-lg md:!tw-h-[400px] lg:!tw-h-[480px] tw-object-cover"
                :img-style="{ width: 'auto' }"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="images.length && images.length > 1"
        class="tw-mt-5xl tw-hidden tw-justify-center tw-gap-x-lg md:tw-flex"
      >
        <UIButton
          class="tw-bg-orange-500"
          icon-left-name="mdi:chevron-left"
          size="large"
          :aria-label="`${$t('common.previous')} ${$t('influencer.dashboard.inspector.askedFiles.extra_pictures')}`"
          @click="setPrevSelectedImg"
        />
        <UIButton
          class="tw-bg-orange-500"
          size="large"
          icon-left-name="mdi:chevron-right"
          :aria-label="`${$t('common.next')} ${$t('influencer.dashboard.inspector.askedFiles.extra_pictures')}`"
          @click="setNextSelectedImg"
        />
      </div>
    </div>
  </DatoSectionContainer>
</template>
